export const ChartIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_8055_5567"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8055_5567)">
        <path
          d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 14.125V15.8333H15.8333V8.54167L10.875 14.1667L7.5 10.7917L4.16667 14.125ZM4.16667 11.75L7.5 8.41667L10.7917 11.7083L15.8333 6.04167V4.16667H4.16667V11.75ZM4.16667 8.54167V6.04167V11.7083V8.41667V14.125V10.7917V14.1667V8.54167ZM4.16667 11.75V4.16667V11.7083V8.41667V11.75ZM4.16667 14.125V10.7917V14.1667V8.54167V15.8333V14.125Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
