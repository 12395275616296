import { useGetReportFilters } from "../../../services/report/useGetReportFilters";
import { ReportLabelType, ReportResponse } from "../../../types/Report";
import { ReportFilterId } from "../../../types/ReportFilter";
import { useRouterReportFilters } from "../../molecules/ReportFilters/ReportFilters.context";
import { useConstants } from "./useConstants";

type UseLabelsOptions = {
  dataSet: NonNullable<ReportResponse>;
};

export function useReportLabels({ dataSet }: UseLabelsOptions) {
  const { addFilter, filters: queryParams } = useRouterReportFilters();
  const { data: filters } = useGetReportFilters(queryParams);
  const { AVG_GRAY, FALLBACK_COLOR } = useConstants();

  const averageLabels: ReportLabelType[] =
    dataSet?.averages && dataSet.averages.length > 0
      ? dataSet.averages?.map((line) => ({
          color: AVG_GRAY,
          title: line.title,
        }))
      : [];
  const dataLabels: ReportLabelType[] =
    dataSet?.data.length > 0
      ? dataSet.data.map((line) => ({
          id: line.filter_id,
          color: `var(--bg-${line.education_level?.label?.toLowerCase()}-base, ${FALLBACK_COLOR})`,
          title: line.title,
          value: line.id,
        }))
      : [];

  const handleLabelClick = (label: ReportLabelType) => (e: React.MouseEvent<HTMLDivElement>) => {
    if (!label.id) {
      return;
    }
    const filter = filters?.find((filter) => filter.field_id === label.id);
    const valueList = filter?.field_control === "multiselect" ? [label.value!] : label.value!;
    if (!valueList) {
      return;
    }

    addFilter(label.id as ReportFilterId, valueList as string[], {
      replaceExisting: true,
    });
  };

  return {
    labels: [...averageLabels, ...dataLabels],
    handleLabelClick,
  };
}
