import { Popover } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import React from "react";
import { AuthorizedCan } from "../../atoms/AuthorizedCan/AuthorizedCan";
import { Edit, Eye } from "../../atoms/icons";
import { OptionSelectorVariant } from "../../atoms/OptionSelector/OptionSelector";
import { SignOutButton } from "../SignOutButton";
import { useMode } from "./useMode";
import { usePopover } from "./usePopover";
import styles from "./UserMenu.module.scss";

type Props = {
  children: React.ReactNode;
};

export const UserMenu: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { popover } = usePopover();
  const { mode, handleModeChange } = useMode();

  return (
    <Popover>
      <Popover.Button as="div" ref={popover.setRefButton} className={styles.UserMenu__Button}>
        {children}
      </Popover.Button>
      <Popover.Overlay className={styles.UserMenu_Overlay} />
      <Popover.Panel
        ref={popover.setRefPanel}
        className={styles.UserMenu__Panel}
        style={popover.styles.popper}
        {...popover.attributes.popper}
      >
        <div className={styles.UserMenu__Card}>
          <AuthorizedCan has={"rubric_rate"}>
            <div>
              <p className={styles.UserMenu__ModeLabel}>{t("application_mode") + ": "}</p>
              <OptionSelectorVariant
                options={[
                  {
                    label: (
                      <>
                        <Edit />
                        <span>{t("edit")}</span>
                      </>
                    ),
                    value: "edit",
                  },
                  {
                    label: (
                      <>
                        <Eye />
                        <span>{t("view")}</span>
                      </>
                    ),
                    value: "view",
                  },
                ]}
                selected={mode}
                onChange={handleModeChange}
              />
            </div>
          </AuthorizedCan>
          <SignOutButton />
        </div>
      </Popover.Panel>
    </Popover>
  );
};
