import { Menu } from "../../molecules/Menu";
import { useTranslation } from "react-i18next";
import React from "react";
import { Home } from "../../atoms/icons";
import { MenuLink } from "../../molecules/Menu/MenuLink";
import styles from "./Sidebar.module.scss";

export const SidebarStudent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuLink href="/">
        <Home />
        <span className={styles.menuItemText}>{t("dashboard")}</span>
      </MenuLink>
    </Menu>
  );
};
