import React, { PropsWithChildren, useState } from "react";
import { GraphTooltipData } from "../../../types/ReportGraph";

export const ReportTooltipContext = React.createContext<{
  tooltipData: GraphTooltipData | null;
  setTooltipData: React.Dispatch<React.SetStateAction<GraphTooltipData | null>>;
  hoveringTooltip: boolean;
  setHoveringTooltip: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  tooltipData: null,
  setTooltipData: () => {},
  hoveringTooltip: false,
  setHoveringTooltip: () => {},
});

export const useTooltip = () => {
  const props = React.useContext(ReportTooltipContext);

  if (props === undefined) {
    throw new Error("useTooltip must be used within a ReportTooltipProvider");
  }

  return props;
};

export const ReportTooltipProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tooltipData, setTooltipData] = useState<GraphTooltipData | null>(null);
  const [hoveringTooltip, setHoveringTooltip] = useState(false);

  return (
    <ReportTooltipContext.Provider value={{ tooltipData, setTooltipData, hoveringTooltip, setHoveringTooltip }}>
      {children}
    </ReportTooltipContext.Provider>
  );
};
