export function getInitialsFromName(name: string) {
  const names = name.split(" ");
  let initials = names.map((name) => {
    // if the name is a single character, return it
    if (name.length === 1) {
      return name;
    }
    // if the first character is uppercase, return it
    if (name[0] && name[0] === name[0].toUpperCase()) {
      return name[0];
    }

    return;
  });

  // always return the first and last initials
  if (initials.length > 1) {
    initials = [initials[0], initials[initials.length - 1]];
  }

  return initials.filter((i) => i !== undefined).join("");
}
