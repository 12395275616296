import classNames from "classnames";
import { HTMLProps } from "react";
import { Spinner } from "../../atoms/Spinner/Spinner";
import styles from "./ReportGraph.module.scss";

export const ReportGraphLoader: React.FC<HTMLProps<HTMLDivElement>> = ({ className, ...props }) => {
  return (
    <div data-testid="report-graph-loader" className={classNames(styles.graphLoader, className)} {...props}>
      <Spinner className={styles.graphSpinner} />
    </div>
  );
};
