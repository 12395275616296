import { useState } from "react";
import { usePopper } from "react-popper";

export function usePopover() {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  return {
    popover: {
      refButton: referenceElement,
      refPanel: popperElement,
      setRefButton: setReferenceElement,
      setRefPanel: setPopperElement,
      styles,
      attributes,
    },
  };
}
