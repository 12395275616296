import { FC } from "react";
import classNames from "classnames";
import "react-dropdown-tree-select/dist/styles.css";
import { BlockProps } from "./Block.types";
import styles from "./Block.module.scss";

export const Block: FC<BlockProps> = ({ children, ...rest }) => (
  <div {...rest} className={classNames(styles.Block, rest.className, "block")}>
    {children}
  </div>
);
