export type AvatarSize = (typeof AVATAR_SIZES)[keyof typeof AVATAR_SIZES];

export const AVATAR_SIZES = {
  SM: 32,
  MD: 46,
  LG: 69,
  XL: 96,
} as const;

export const AVATAR_STROKE_WIDTHS: { [K in AvatarSize]: number } = {
  [AVATAR_SIZES.SM]: 1,
  [AVATAR_SIZES.MD]: 2,
  [AVATAR_SIZES.LG]: 3,
  [AVATAR_SIZES.XL]: 4,
} as const;

export const AVATAR_GAP_WIDTHS: { [K in AvatarSize]: number } = {
  [AVATAR_SIZES.SM]: 5,
  [AVATAR_SIZES.MD]: 6,
  [AVATAR_SIZES.LG]: 8,
  [AVATAR_SIZES.XL]: 10,
} as const;
