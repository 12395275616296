import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { useGetReportFilters } from "../../../services/report/useGetReportFilters";
import { ReportType } from "../../../types/Report";
import { ReportFilterId, ReportFilterQueryParam } from "../../../types/ReportFilter";

type ReportFiltersContextType = {
  reportType: ReportType;
  filters: {
    [key in ReportFilterQueryParam]?: string | string[];
  };
  queryFilters: [string, string | string[] | undefined][];
  addFilter: (filterId: ReportFilterId, value: string | string[], options?: { replaceExisting?: boolean }) => void;
  removeFilter: (filterId: ReportFilterId) => void;
  areRequiredFiltersMissing: boolean;
};

const ReportFiltersContext = createContext<ReportFiltersContextType | null>(null);

export const useRouterReportFilters = () => {
  const context = useContext(ReportFiltersContext);

  if (!context) {
    throw new Error("useRouterReportFilters must be used within a ReportFiltersProvider");
  }

  return context;
};

export const ReportFiltersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const reportType = router.query.report_type as ReportType;
  const queryClient = useQueryClient();
  const queryFilters = Object.entries(router.query).filter(([key]) => key.startsWith("filter"));
  const filters = queryFilters.reduce((acc, [key, value]) => {
    const filterId = key.replace("filter[", "").replace("]", "").replace("[]", "") as ReportFilterId;
    let filterKey = `filter[${filterId}]`;
    // If the filter is a subject filter, it can have multiple values.
    if (key.endsWith("[]") && !Array.isArray(value)) {
      filterKey = `filter[${filterId}][]`;
    }

    return {
      ...acc,
      [filterKey]: value as string | string[],
    };
  }, {}) as ReportFiltersContextType["filters"];
  const { data: dataFilters } = useGetReportFilters(filters);
  const areRequiredFiltersMissing =
    dataFilters?.some((filter) => {
      return filter.field_required && queryFilters.every(([key]) => !key.startsWith(`filter[${filter.field_id}]`));
    }) ?? true;

  const addFilter = (
    filterId: ReportFilterId,
    value: string | string[],
    options?: {
      replaceExisting?: boolean;
    }
  ) => {
    let newFilters = router.query[`filter[${filterId}]`]
      ? [...(router.query[`filter[${filterId}]`] as string[]), value].flat()
      : value;

    // Replace filter if replace is true
    if (options?.replaceExisting) {
      newFilters = value;
    }

    queryClient.invalidateQueries({
      queryKey: ["reports", "filters", reportType],
    });
    let filterKey = `filter[${filterId}]`;
    if (Array.isArray(newFilters)) {
      filterKey = `filter[${filterId}][]`;
    }

    router.push({
      query: {
        ...router.query,
        [filterKey]: newFilters as string[],
      },
    });
  };

  const removeFilter = (filterId: ReportFilterId) => {
    delete router.query[`filter[${filterId}]`];
    delete router.query[`filter[${filterId}][]`];
    router.push({
      query: {
        ...router.query,
      },
    });
  };

  // Remove filters that don't exist in the new filters
  useEffect(() => {
    const newFilters = dataFilters;
    const currentFilters = Object.entries(router.query)
      .filter(([key]) => key.startsWith("filter"))
      .map(([key, value]) => ({
        field_id: key.replace("filter[", "").replace("]", "").replace("[]", ""),
        field_options: Array.isArray(value) ? value.map((v) => ({ value: v })) : [{ value }],
      }));

    currentFilters.forEach((curFilter) => {
      // if new filters arent loaded yet, don't remove any filters
      if (!newFilters) {
        return;
      }
      if (
        newFilters?.some((newFilter) => {
          // If the filter is a date range, check if the selected option is the same as the current filter
          if (newFilter.field_control === "date" && newFilter.field_id === curFilter.field_id) {
            const selectedOption = newFilter.field_options?.find(
              (o) => o.value === curFilter.field_options.map((o) => o.value).join(",")
            );

            return (
              selectedOption?.selected ??
              // If the selected option is undefined, check if the dates are the same
              (newFilter.field_from === curFilter.field_options[0].value &&
                newFilter.field_to === curFilter.field_options[1].value)
            );
          }

          // If the filter is a select, check if the selected option is the same as the current filter
          return (
            newFilter.field_id === curFilter.field_id &&
            newFilter.field_options?.some(
              (v) => curFilter.field_options.find((o) => o.value === v.value.toString()) !== undefined
            )
          );
        })
      ) {
        return;
      }
      const foundFilter = newFilters.find((f) => f.field_id === curFilter.field_id);
      if (!foundFilter || foundFilter.field_control === "date") {
        return;
      }

      // Only remove filters that dont have a value
      delete router.query[`filter[${curFilter.field_id}]`];
      delete router.query[`filter[${curFilter.field_id}][]`];
    });
    router.replace({
      query: {
        ...router.query,
      },
    });
  }, [dataFilters]);

  return (
    <ReportFiltersContext.Provider
      value={{ filters, addFilter, removeFilter, reportType, queryFilters, areRequiredFiltersMissing }}
    >
      {children}
    </ReportFiltersContext.Provider>
  );
};
