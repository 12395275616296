import { FC } from "react";
import Select from "react-select";
import styles from "./CustomSelect.module.scss";
import { CustomSelectProps } from "./CustomSelect.types";

export const CustomSelect: FC<CustomSelectProps> = ({ options, value, ...props }) => {
  return (
    // the key prop is provided due to react-select can't handle undefined values for value prop correctly
    // this onWheel fixes not being able to scroll long lists in portal (e.g. the subject filter in the rubric clone dialog)
    <div className={styles.customSelect} onWheel={(e) => e.stopPropagation()}>
      <Select
        key={value?.value}
        placeholder="Select a value"
        isSearchable={true}
        className="react-select"
        classNamePrefix="react-select"
        data-testid="custom-select"
        options={options}
        value={value}
        // We use a portal to display the dropdown menu outside of the parent component
        // this solves issue with overflow hidden on parent component (e.g. dialog or modal)
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999, pointerEvents: "all" }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen ? "scaleY(-1)" : "",
            color: "hsl(0, 0%, 80%)",
            "&:hover": {
              color: "hsl(0, 0%, 80%)",
            },
          }),
          control: (styles, state) => ({
            ...styles,
            marginLeft: "1px",
            boxShadow: state.isFocused ? "0 0 0 1px var(--color-focus)" : "0",
            borderColor: state.isFocused ? "var(--color-focus)" : "#CED4DA",
            "&:hover": {
              borderColor: state.isFocused ? "var(--color-focus)" : "#CED4DA",
            },
          }),
        }}
        menuShouldScrollIntoView={false}
        menuPlacement="auto"
        {...props}
      />
    </div>
  );
};
