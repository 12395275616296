export const ChartCollectionIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 45C18.625 45 17.4479 44.5104 16.4688 43.5312C15.4896 42.5521 15 41.375 15 40V10C15 8.625 15.4896 7.44792 16.4688 6.46875C17.4479 5.48958 18.625 5 20 5H50C51.375 5 52.5521 5.48958 53.5312 6.46875C54.5104 7.44792 55 8.625 55 10V40C55 41.375 54.5104 42.5521 53.5312 43.5312C52.5521 44.5104 51.375 45 50 45H20ZM20 40H50V10H20V40ZM10 55C8.625 55 7.44792 54.5104 6.46875 53.5312C5.48958 52.5521 5 51.375 5 50V17.5C5 16.7917 5.23958 16.1979 5.71875 15.7188C6.19792 15.2396 6.79167 15 7.5 15C8.20833 15 8.80208 15.2396 9.28125 15.7188C9.76042 16.1979 10 16.7917 10 17.5V50H42.5C43.2083 50 43.8021 50.2396 44.2812 50.7188C44.7604 51.1979 45 51.7917 45 52.5C45 53.2083 44.7604 53.8021 44.2812 54.2812C43.8021 54.7604 43.2083 55 42.5 55H10Z"
        fill="currentColor"
      />
      <path
        d="M19.4167 44.75C18.2021 44.75 17.1623 44.3175 16.2974 43.4526C15.4325 42.5877 15 41.5479 15 40.3333V9.41667C15 8.20208 15.4325 7.16233 16.2974 6.2974C17.1623 5.43247 18.2021 5 19.4167 5H50.3333C51.5479 5 52.5877 5.43247 53.4526 6.2974C54.3175 7.16233 54.75 8.20208 54.75 9.41667V40.3333C54.75 41.5479 54.3175 42.5877 53.4526 43.4526C52.5877 44.3175 51.5479 44.75 50.3333 44.75H19.4167ZM19.4167 35.8062V40.3333H50.3333V21.0104L38.7396 34.15C38.3347 34.6285 37.8102 34.8769 37.1661 34.8953C36.522 34.9137 35.9792 34.7021 35.5375 34.2604L28.25 26.9729L19.4167 35.8062ZM19.4167 29.5125L26.7042 22.225C27.1458 21.7833 27.6611 21.5625 28.25 21.5625C28.8389 21.5625 29.3542 21.7833 29.7958 22.225L36.9729 29.4021L50.3333 14.3854V9.41667H19.4167V29.5125ZM19.4167 21.0104V14.3854V29.4021V20.6792V35.8062V26.9729V35.9167V21.0104ZM19.4167 29.5125V9.41667V29.4021V20.6792V29.5125ZM19.4167 35.8062V26.9729V35.9167V21.0104V40.3333V35.8062Z"
        fill="currentColor"
      />
    </svg>
  );
};
