import { useIsFetching } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TreeSelect } from "../../../TreeSelect/TreeSelect";
import { TreeMetaNode, TreeSelectedNode } from "../../../TreeSelect/useTreeSelect";
import { ReportFieldProps } from "../../ReportField";
import { useRouterReportFilters } from "../../ReportFilters.context";

export const ReportFieldMultiSelect: React.FC<ReportFieldProps> = ({
  field_id,
  field_label,
  field_disabled,
  field_required,
  field_options,
}) => {
  const { t } = useTranslation();
  const { addFilter, removeFilter, filters } = useRouterReportFilters();
  const isFetching = useIsFetching(["report", "filters"]) > 0;
  const [selectedState, setSelectedState] = useState<TreeSelectedNode<TreeMetaNode>[]>([]);
  const handleChange = (value: TreeSelectedNode<TreeMetaNode>[]) => {
    const ids = value.map((v) => v.meta.id.toString());

    if (ids.length === 0) {
      removeFilter(field_id);
      return;
    }

    addFilter(field_id, ids, {
      replaceExisting: true,
    });
  };

  const debouncedHandleChange = useCallback(
    debounce(async (value) => {
      setSelectedState(value);
      handleChange(value);
    }, 500),
    [handleChange, setSelectedState]
  );

  const selectedOptions = field_options?.filter((o) => o.selected);
  const label = `${field_label}${field_required ? " *" : ""}`;
  let placeholder = selectedOptions?.length > 1 ? t("select_total", { selected: selectedOptions.length }) : t("select");
  if (selectedOptions?.length === 1) {
    placeholder = selectedOptions[0].label;
  }

  const localSelectedState = field_options.map((o) => {
    o.selected = selectedState.some((s) => s.meta.id === o.value);
    return o;
  });

  // Make sure the selected state is updated when the filters change
  useEffect(() => {
    const selected = filters?.[`filter[${field_id}]`] || filters?.[`filter[${field_id}][]`];
    if (!selected) {
      return;
    }

    const selectedIds = Array.isArray(selected) ? selected : [selected];
    const selectedNodes = field_options
      .filter((o) => selectedIds.includes(o.value.toString()))
      .map((o) => {
        return {
          name: o.label,
          label: o.label,
          checked: true,
          id: o.value as string,
          meta: {
            id: o.value as string,
          },
        };
      });

    setSelectedState(selectedNodes as unknown as TreeSelectedNode<TreeMetaNode>[]);
  }, [filters]);

  // when fetching data, the checkboxes are disabled.
  // When disabled, we want to already show the 'new' selected state (instead of the current field_options)
  const displayData = isFetching ? localSelectedState : field_options;

  return (
    <TreeSelect
      label={label}
      placeholder={placeholder}
      disabled={field_disabled || isFetching}
      name={field_id}
      data={displayData?.map((o) => {
        return {
          name: o.label,
          id: o.value as string,
          meta: {
            id: o.value as string,
          },
        };
      })}
      selected={field_options.reduce((acc, o, i) => {
        return { ...acc, [i]: o.selected };
      }, {})}
      onChange={debouncedHandleChange}
    />
  );
};
