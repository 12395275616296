import { FC, useEffect, useState } from "react";
import styles from "./ReportTooltip.module.scss";
import { useTooltip } from "./ReportTooltip.context";
import { ChevronRight } from "../../atoms/icons";
import { Button, ButtonVariant } from "../../atoms/Button";
import { ButtonSize } from "../../atoms/Button/Button.types";

export const ReportTooltip: FC = () => {
  const { tooltipData, setHoveringTooltip } = useTooltip();
  const [tooltipIndex, setTooltipIndex] = useState(0);

  const activeTooltip = tooltipData?.tooltips[tooltipIndex];
  const tooltipColor = activeTooltip?.color;
  const hasNext = tooltipData?.tooltips && tooltipIndex < tooltipData?.tooltips.length - 1;

  const goNextTooltip = () => {
    setTooltipIndex(hasNext ? tooltipIndex + 1 : 0);
  };

  useEffect(() => {
    setTooltipIndex(0);
  }, [
    tooltipData?.position?.top,
    tooltipData?.position?.bottom,
    tooltipData?.position?.left,
    tooltipData?.position?.right,
  ]);

  return (
    <div
      id="tooltip-container"
      onPointerMove={() => setHoveringTooltip(true)}
      onPointerLeave={() => setHoveringTooltip(false)}
      className={styles.tooltipContainer}
      style={{
        top: tooltipData?.position?.top || "0",
        bottom: tooltipData?.position?.bottom || "auto",
        left: tooltipData?.position?.left || "0",
        right: tooltipData?.position?.right || "auto",
        opacity: tooltipData?.position?.opacity || "0",
        pointerEvents: tooltipData?.position?.opacity === "0" ? "none" : "all",
        backgroundColor: tooltipColor ? `var(--bg-${tooltipColor}-1-hover)` : "white",
      }}
    >
      <div
        className={styles.tooltipDot}
        style={{
          backgroundColor: tooltipColor ? `var(--bg-${tooltipColor}-base)` : "black",
          top: tooltipData?.position?.top === "auto" ? "auto" : "-14px",
          bottom: tooltipData?.position?.bottom === "auto" ? "auto" : "-14px",
          left: tooltipData?.position?.left === "auto" ? "auto" : "-7px",
          right: tooltipData?.position?.right === "auto" ? "auto" : "-7px",
        }}
      ></div>
      {activeTooltip && (
        <div>
          <div className={styles.tooltipDate} style={{ color: tooltipColor ? `var(--bg-${tooltipColor}-3)` : "black" }}>
            <div>
              {activeTooltip.datapoint &&
                new Date(activeTooltip.datapoint.date).toLocaleDateString("nl-NL", {
                  month: "long",
                  year: "numeric",
                })}
            </div>
            {/* @TODO: check later is this is required to show (percentage of the hovered line if multiple meta.items) */}
            {/* <div>
                {tooltipData.datapoint?.progress_percentage && tooltipData.datapoint.progress_percentage + "%"}
              </div> */}
          </div>
          {activeTooltip.entity && (
            <div
              className={styles.tooltipTitle}
              style={
                {
                  color: "var(--color)",
                  "--color": tooltipColor ? `var(--bg-${tooltipColor}-3)` : "black",
                } as React.CSSProperties
              }
            >
              <div style={{ fontWeight: 500 }}>{activeTooltip.entity.title}</div>
              {tooltipData.tooltips.length > 1 && (
                <div className={styles.chevronContainer}>
                  <Button size={ButtonSize.SMALL} variant={ButtonVariant.PLAIN} onClick={() => goNextTooltip()}>
                    <ChevronRight />
                  </Button>
                </div>
              )}
            </div>
          )}
          {activeTooltip.datapoint?.meta && (
            <>
              {activeTooltip.datapoint?.meta?.items && (
                <div style={{ marginTop: "12px" }}>
                  {activeTooltip.datapoint.meta.items.map((meta) => (
                    <div key={meta.id} className={styles.tooltipStat}>
                      <div>{meta.title}</div>
                      <div key={meta.id}>{meta.progress_percentage}%</div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
