import React from "react";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import { CustomSelect } from "../../atoms/CustomSelect";
import styles from "./SelectControl.module.scss";
import { SelectControlOption, SelectControlProps } from "./SelectControl.types";

export const SelectControl = React.forwardRef<HTMLDivElement, SelectControlProps>(function SelectControl(
  { options, value, onChange, label, name, placeholder, disabled, isClearable }: SelectControlProps,
  ref
) {
  const { t } = useTranslation();
  const handleChange = (newValue: OnChangeValue<SelectControlOption, false>) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={styles.SelectControl}>
      {label && (
        <label htmlFor={name} className={styles.SelectControlLabel}>
          {label}
        </label>
      )}
      <CustomSelect
        ref={ref}
        name={name}
        isDisabled={disabled}
        placeholder={placeholder}
        className={styles.SelectControlSelect}
        isClearable={isClearable}
        options={options}
        value={value!}
        noOptionsMessage={() => t("no_options")}
        // @ts-ignore
        onChange={handleChange}
      />
    </div>
  );
});
