import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect } from "react";
import { useAuthorization, ValidatePermissionStrategy } from "../../../context/AuthorizationContext";
import { metaKeys } from "../../../services/metas/metaKeys";
import { AuthorizationPermissionKey } from "../../../types/Authorization";
import { showToast } from "../../../services/toast/toast";
import { useTranslation } from "react-i18next";

type Props = {
  has: AuthorizationPermissionKey | AuthorizationPermissionKey[];
  strategy?: ValidatePermissionStrategy;
  redirect?: string;
  children: React.ReactNode;
};

export const AuthorizedGuard: React.FC<Props> = ({ has: permission, strategy, redirect, children }) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation(["common", "errors"]);
  const metaQuery = queryClient.getQueryState(metaKeys.all); // meta contains the permissions of the user
  const isAllowed = hasPermission(permission, { strategy });

  useEffect(() => {
    if (!isAllowed && metaQuery?.status === "success") {
      router.push(redirect ?? "/").then(() => showToast({ type: "error", message: t("errors:unauthorized_page") }));
    }
  }, [isAllowed, redirect, router, metaQuery?.status, t]);

  if (!isAllowed) {
    return null;
  }

  return <>{children}</>;
};
