import { ExpandedState, FilterFnOption, Row, filterFns } from "@tanstack/react-table";
import { useState } from "react";
import { TreeDataItem } from "./Tree";

type TreeOptions = {};

export function useTree({}: TreeOptions) {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const globalFilterFn: FilterFnOption<TreeDataItem> = (row, column, filterValue, meta) => {
    const subRowsFilterFn = (subRows: Row<TreeDataItem>[]) => {
      return subRows.some((subRow) => subRowFilterFn(subRow));
    };
    const subRowFilterFn = (subRow: Row<TreeDataItem>): boolean => {
      const res = filterFns.includesString(subRow, column, filterValue, meta);
      if (!res && subRow.subRows) return subRowsFilterFn(subRow.subRows);
      return res;
    };

    // If the row has subRows, call the function recursively on them
    if (row.subRows) {
      row.toggleExpanded(true);
    }

    return subRowsFilterFn(row.subRows) || filterFns.includesString(row, column, filterValue, meta);
  };

  return {
    globalFilterFn,
    expanded,
    setExpanded,
  };
}
