import Link from "next/link";
import React from "react";
import { ChevronRight } from "../../atoms/icons";

type Props = {
  href?: string;
  hasArrow?: boolean;
  children: React.ReactNode;
};

export const BreadcrumbLink: React.FC<Props> = ({ children, href, hasArrow }) => {
  if (!href) {
    return (
      <li>
        <span>{children}</span>
        {hasArrow && <ChevronRight aria-hidden />}
      </li>
    );
  }

  return (
    <li>
      <Link href={href} passHref>
        <a aria-current={href && hasArrow ? "page" : undefined}>{children}</a>
      </Link>
      {hasArrow && <ChevronRight aria-hidden />}
    </li>
  );
};
