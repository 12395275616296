import Link from "next/link";
import React from "react";
import styles from "./Menu.module.scss";
import { useRouter } from "next/router";

type MenuLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href?: string;
  active?: boolean;
  activeRoutes?: string[];
};

export const MenuLink: React.FC<MenuLinkProps> = ({ href, active, activeRoutes, children, ...props }) => {
  const router = useRouter();

  const isActive = active || router.pathname === href || activeRoutes?.includes(router.pathname);

  if (!href) {
    return (
      <li>
        <a className={styles.Menu_link} {...props}>
          {children}
        </a>
      </li>
    );
  }

  return (
    <li>
      <Link href={href} passHref>
        <a className={styles.Menu_link} data-active={isActive} {...props}>
          {children}
        </a>
      </Link>
    </li>
  );
};
