import * as RadixAvatar from "@radix-ui/react-avatar";

import { AVATAR_GAP_WIDTHS, AVATAR_SIZES, AVATAR_STROKE_WIDTHS } from "./Avatar.constants";
import styles from "./Avatar.module.scss";
import {
  AvatarBorderCSSProps,
  AvatarCSSProps,
  AvatarClipCSSProps,
  AvatarProps,
  AvatarRingCSSProps,
} from "./Avatar.types";

export function Avatar({ image, size = AVATAR_SIZES.MD, color, initials = "", dataMinimized }: AvatarProps) {
  const strokeWidth = AVATAR_STROKE_WIDTHS[size];
  const gapWidth = AVATAR_GAP_WIDTHS[size];
  const avatarSize = size;
  const ringSize = size;
  const imageSize = size - gapWidth - strokeWidth;

  const avatarStyles: AvatarCSSProps = {
    "--size": `${avatarSize}px`,
  };

  const avatarClipStyles: AvatarClipCSSProps = {
    "--stroke-width": `${strokeWidth}px`,
    "--gap-width": `${gapWidth}px`,
    "--size": `${imageSize}px`,
  };

  const avatarRingStyles: AvatarRingCSSProps = {
    "--stroke-width": `${strokeWidth}px`,
    "--size": `${ringSize}px`,
  };

  const hasImage = !!image;

  return (
    <figure data-testid="avatar" className={styles.Avatar} style={avatarStyles} data-minimized={dataMinimized}>
      <div
        data-testid="avatar-ring"
        className={styles.AvatarRing}
        style={
          {
            ...avatarRingStyles,
            "--avatar-border-color": `var(--bg-${color}-base)`,
          } as AvatarBorderCSSProps
        }
      />
      <RadixAvatar.Root data-testid="avatar-clip" className={styles.AvatarClip} style={avatarClipStyles}>
        {hasImage && (
          <RadixAvatar.Image data-testid="avatar-image" src={image} alt="Avatar" width={imageSize} height={imageSize} />
        )}
        <RadixAvatar.Fallback className={styles.AvatarFallback} delayMs={hasImage ? 600 : 0}>
          {initials}
        </RadixAvatar.Fallback>
      </RadixAvatar.Root>
    </figure>
  );
}
