import classNames from "classnames";
import { HTMLProps } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ReportGraph.module.scss";

export const ReportGraphNoData: React.FC<HTMLProps<HTMLDivElement>> = ({ className, ...props }) => {
  const { t } = useTranslation();
  return (
    <div data-testid="report-graph-no-data" className={classNames(styles.graphNoData, className)} {...props}>
      <span>{t("no_matches")}</span>
    </div>
  );
};
