import { CellContext, Row } from "@tanstack/react-table";
import classNames from "classnames";
import { Button, ButtonVariant } from "../Button";
import { ButtonSize } from "../Button/Button.types";
import { Checkbox } from "../Checkbox/Checkbox";
import { ChevronDown, ChevronRight } from "../icons";
import { TreeDataItem } from "./Tree";
import styles from "./Tree.module.scss";

const TREE_NODE_SPACE = 18;

type Props = CellContext<TreeDataItem, string>;

export const areSomeSubRowsSelected = (row: Row<TreeDataItem>): boolean => {
  if (!row.subRows.length) return false;

  if (row.subRows.some((subRow) => subRow.getIsSomeSelected())) {
    return true;
  }

  if (row.subRows.some((subRow) => areSomeSubRowsSelected(subRow))) {
    return true;
  }

  return false;
};

export const TreeNode: React.FC<Props> = ({ row, cell, table }) => {
  const isSelected = row.getIsSelected() || row.getIsAllSubRowsSelected();
  const canSelect = row.getCanSelect();
  const isSomeSelected = row.getIsSomeSelected() || areSomeSubRowsSelected(row);
  const canExpand = row.getCanExpand();
  const isExpanded = row.getIsExpanded();

  const handleToggle = canExpand ? row.getToggleExpandedHandler() : undefined;

  return (
    <li
      role="treeitem"
      aria-selected={isSelected}
      className={styles.tree__node}
      title={cell.getValue()}
      style={{
        cursor: canExpand ? "pointer" : "default",
      }}
    >
      {canExpand ? (
        <Button
          size={ButtonSize.XSMALL}
          variant={ButtonVariant.PLAIN}
          onClick={handleToggle}
          style={{
            paddingLeft: `${row.depth * TREE_NODE_SPACE}px`,
            width: "auto",
          }}
          className={classNames(styles.tree__expand)}
          icon={
            isExpanded ? (
              <ChevronDown width={TREE_NODE_SPACE} height={TREE_NODE_SPACE} />
            ) : (
              <ChevronRight width={TREE_NODE_SPACE} height={TREE_NODE_SPACE} />
            )
          }
        />
      ) : (
        <div style={{ paddingLeft: `${row.depth * TREE_NODE_SPACE + TREE_NODE_SPACE / 2}px` }} />
      )}
      <Checkbox
        id={row.id}
        name={row.id}
        aria-labelledby={`label-${row.id}`}
        checked={isSelected}
        disabled={!canSelect}
        isIndeterminate={isSomeSelected}
        onCheckedChange={row.getToggleSelectedHandler()}
      >
        {cell.getValue()}
      </Checkbox>
    </li>
  );
};
