import { FC, useState } from "react";
import { TooltipProps } from "./Tooltip.types";
import { Popover, ArrowContainer } from "react-tiny-popover";
import styles from "./Tooltip.module.scss";
import classNames from "classnames";

export const Tooltip: FC<TooltipProps> = ({ children, content, tabIndex }) => {
  const [isPopoverOpen, setPopOverOpen] = useState<boolean>(false);

  const arrowSize = 16; // if you change the arrow size, update scss variable for shadow as well
  const arrowColor = "white";

  const toggleIsPopoverOpen = (e: any) => {
    e.preventDefault();
    setPopOverOpen((prevState) => !prevState);
  };

  return (
    <div data-testid="tooltip">
      <Popover
        isOpen={isPopoverOpen}
        padding={10}
        onClickOutside={() => setPopOverOpen(false)}
        containerClassName={styles.tooltipContainer}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={arrowColor}
            arrowSize={arrowSize}
            className={styles.tooltipArrowContainer}
            arrowClassName={classNames(styles.tooltipArrow, styles[`position-${position}`])}
          >
            <div className={styles.tooltipContent}>{content}</div>
          </ArrowContainer>
        )}
      >
        <a href="#" onClick={toggleIsPopoverOpen} tabIndex={tabIndex}>
          {children}
        </a>
      </Popover>
    </div>
  );
};
