import Link from "next/link";
import React, { FC } from "react";
import { Button } from "../../atoms/Button";
import { ButtonSize } from "../../atoms/Button/Button.types";
import { Breadcrumbs } from "../Breadcrumbs";
import { BreadcrumbLink } from "../Breadcrumbs/BreadcrumbLink";
import styles from "./PageHeader.module.scss";
import { PageHeaderButtonActionType, PageHeaderProps } from "./PageHeader.types";

export const PageHeader: FC<PageHeaderProps> = ({ title, titleSuffix, breadcrumbs, actions }) => (
  <div className={styles.PageHeader} data-testid="page-header">
    <div>
      {breadcrumbs && (
        <Breadcrumbs>
          {breadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbLink key={index} href={breadcrumb.href} hasArrow={index < breadcrumbs.length - 1}>
              {breadcrumb.label}
            </BreadcrumbLink>
          ))}
        </Breadcrumbs>
      )}
      <h1 className={styles.PageHeaderTitle}>{title}</h1>
      {titleSuffix && <h2 className={styles.PageHeaderTitleSuffix}>{titleSuffix}</h2>}
    </div>
    <div className={styles.actions}>
      {actions &&
        actions.map((action, index) => {
          if (React.isValidElement(action)) return action;
          const _action = action as PageHeaderButtonActionType;
          if (!_action.label) return null;

          const button = (
            <Button
              variant={_action.variant}
              onClick={_action.onClick}
              disabled={_action.disabled}
              size={ButtonSize.LARGE}
              loading={_action.loading}
              data-testid={_action.testId}
              key={index}
            >
              {_action.label}
            </Button>
          );
          if (_action.href) {
            return (
              <Link key={index} href={_action.href}>
                <a>{button}</a>
              </Link>
            );
          }
          return button;
        })}
    </div>
  </div>
);
