import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import defaultStyles from "./OptionSelector.module.scss";
import { OptionSelectorProps, OptionSelectorValue } from "./OptionSelector.types";

export const OptionSelector: React.FC<OptionSelectorProps> = ({
  options,
  selected,
  onChange,
  classes,
  styles,
  disabled = false,
  ...props
}) => {
  const [option, setOption] = useState(selected ?? options[0].value);

  useEffect(() => {
    if (selected !== undefined && option !== selected) {
      setOption(selected);
    }
  }, [selected, option, setOption]);

  const handleChange = (value: OptionSelectorValue) => {
    setOption(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <RadioGroup
      value={option}
      onChange={handleChange}
      disabled={disabled}
      className={classNames(defaultStyles.OptionSelector, classes?.container)}
      data-testid="OptionSelector"
    >
      {options.map((option) => (
        <RadioGroup.Option
          className={defaultStyles.OptionSelectorOptionContainer}
          value={option.value}
          key={option.value}
        >
          {({ checked }) => {
            const optionClass = [defaultStyles.OptionSelectorOption, classes?.option];
            let optionStyle = styles?.option ?? {};

            if (checked) {
              optionClass.push(defaultStyles.OptionSelectorOptionSelected);
              optionClass.push(classes?.optionSelected);
              optionStyle = styles?.optionSelected ?? {};
            }

            return (
              <span className={classNames(...optionClass)} style={optionStyle} {...props}>
                {option.label}
              </span>
            );
          }}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export const OptionSelectorVariant: React.FC<OptionSelectorProps> = (props) => {
  return (
    <OptionSelector
      classes={{
        container: defaultStyles.OptionSelectorVariant,
        option: defaultStyles.OptionSelectorVariantOption,
        optionSelected: defaultStyles.OptionSelectorVariantOptionSelected,
      }}
      {...props}
    />
  );
};
