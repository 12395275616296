import { FC, ReactElement, Suspense } from "react";
import { PageLoaderProps } from "./PageLoader.types";
import styles from "./PageLoader.module.scss";
import { Spinner } from "../Spinner/Spinner";
import Icon from "../icons";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const getErrorMessage = (t: TFunction, error: any): string => {
  if (error === "not-found") {
    return t("page.error.not_found");
  }

  if (error === "generic") {
    return t("page.error.generic");
  }

  if (error?.isApiError) {
    if (error?.status === 404) {
      return t("page.error.not_found");
    }

    if (error?.status === 403) {
      return t("page.error.forbidden");
    }
  }

  return t("page.error.generic");
};

export const PageLoader: FC<PageLoaderProps> = ({ children, isLoading, isError, error }) => {
  const { t } = useTranslation();

  if (isError) {
    const errorMessage = getErrorMessage(t, error);

    return (
      <div className={styles.PageLoader}>
        <div className={styles.PageLoaderError}>
          <Icon name="warning" />
          {errorMessage}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.PageLoader}>
        <div className={styles.PageLoaderSpinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  return children as ReactElement;
};
