import styles from "./ApplicationMode.module.scss";
import { useTranslation } from "react-i18next";
import { Edit, Eye } from "../../atoms/icons";
import React from "react";

export type ModeType = "edit" | "view";
export type ApplicationModeProps = {
  mode: ModeType;
  dataMinimized?: any;
};

export const ApplicationMode: React.FC<ApplicationModeProps> = ({ mode, dataMinimized }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.applicationModeBox} data-minimized={dataMinimized}>
      {mode === "edit" && (
        <>
          <Edit />
          <span className={styles.applicationModeLabel}>{t("edit_mode")}</span>
        </>
      )}
      {mode === "view" && (
        <>
          <Eye />
          <span className={styles.applicationModeLabel}>{t("view_mode")}</span>
        </>
      )}
    </div>
  );
};
