import { useContext } from "react";
import { UserLockContext } from "../../../context/UserLockContext";
import { OptionSelectorValue } from "../../atoms/OptionSelector/OptionSelector.types";

export function useMode() {
  const { isLocked, setLocked } = useContext(UserLockContext);
  const mode = isLocked ? "view" : "edit";
  const handleModeChange = (value: OptionSelectorValue) => {
    if (value === "edit") {
      setLocked(false);
    } else {
      setLocked(true);
    }
  };

  return { handleModeChange, mode };
}
