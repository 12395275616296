import classNames from "classnames";
import React from "react";
import { useAuthorization, ValidatePermissionStrategy } from "../../../context/AuthorizationContext";
import { AuthorizationPermissionKey } from "../../../types/Authorization";
import { Input } from "../../atoms/Input";
import { Textarea } from "../../atoms/Textarea";
import styles from "./FormControl.module.scss";
import { FormControlKey, FormControlProps } from "./FormControl.types";

export const FormControl = React.forwardRef<unknown, FormControlProps>(
  ({ control, label, helperText, error, className, required, ...props }, ref) => {
    const Control = FormControlFactory(control);

    return (
      <div className={classNames([styles.FormControl, className])} data-testid="FormControl">
        {label && (
          <label htmlFor={props.name} className={styles.FormControlLabel}>
            {label}
            {required && <span className={styles.FormControlRequired}>*</span>}
          </label>
        )}
        <Control ref={ref} data-invalid={!!error} {...(props as any)} />
        {helperText && !error && <span className={styles.FormControlHelperText}>{helperText}</span>}
        {error && <span className={styles.FormControlError}>{error}</span>}
      </div>
    );
  }
);

export const AuthorizedFormControl = React.forwardRef<
  unknown,
  FormControlProps & {
    has: AuthorizationPermissionKey | AuthorizationPermissionKey[];
    strategy?: ValidatePermissionStrategy;
  }
>(({ has: permission, strategy, ...props }, ref) => {
  const { hasPermission } = useAuthorization();

  if (!hasPermission(permission, { strategy })) {
    props.disabled = true;
  }

  return <FormControl {...props} ref={ref} />;
});

export const FormControlMap = {
  input: Input,
  textarea: Textarea,
};

const FormControlFactory = (type: FormControlKey) => {
  return FormControlMap[type];
};

FormControl.displayName = "FormControl";
AuthorizedFormControl.displayName = "AuthorizedFormControl";
