import { ReportQueryParams, ReportType } from "../../types/Report";

export const reportKeys = {
  graph: (reportType: ReportType, ...req: ReportQueryParams[]) => {
    return ["report", "graph", reportType, ...req];
  },
  filters: (reportType: ReportType, ...req: ReportQueryParams[]) => {
    return ["report", "filters", reportType, ...req];
  },
};
