import React from "react";
import { TextareaProps } from "./Textarea.types";
import styles from "./Textarea.module.scss";
import classNames from "classnames";

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  return (
    <textarea
      ref={ref}
      className={classNames(styles.Textarea, props.className)}
      data-testid="Textarea"
      {...props}
    ></textarea>
  );
});

Textarea.displayName = "Textarea";
