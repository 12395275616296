import { FC } from "react";
import { ReportSwimlane } from "../../../types/Report";
import { useConstants } from "../../organisms/ReportGraph/useConstants";

type Props = {
  swimlanes?: ReportSwimlane[];
  yScaleFunction?: d3.ScaleLinear<number, number>;
  width: number;
};

export const ReportSwimlanes: FC<Props> = ({ swimlanes, yScaleFunction, width }) => {
  const { GRAPH_PADDING_X_START } = useConstants();
  if (!yScaleFunction || !swimlanes || swimlanes.length === 0) {
    return null;
  }

  const parsedData = swimlanes.map((swimlane) => ({
    title: swimlane.title,
    y: yScaleFunction(swimlane.progress_percentage),
  }));

  return (
    <>
      {parsedData.map((sw) => (
        <g key={sw.title}>
          <line x1={GRAPH_PADDING_X_START} x2={width} y1={sw.y} y2={sw.y} stroke="rgba(238, 118, 0, 0.5)" />
          <text x={GRAPH_PADDING_X_START + 5} y={sw.y} dy={-6} fill="rgb(238, 118, 0)" fontSize="8">
            {sw.title}
          </text>
        </g>
      ))}
    </>
  );
};
