import { OnChangeFn, RowSelectionState, Table } from "@tanstack/react-table";
import { TreeSelection } from "../../molecules/TreeSelect/useTreeSelect";
import { TreeDataItem } from "./Tree";

type HookOptions = {
  selected: TreeSelection;
  onSelected: (selected: TreeSelection) => void;
};

export function useRowSelection({ selected, onSelected }: HookOptions) {
  const handleSelection = (api: Table<TreeDataItem<any>>): OnChangeFn<RowSelectionState> => {
    return (setState) => {
      if (typeof setState !== "function") return;
      const prepareState = { ...setState(selected) };
      const allRows = api.getRowModel().rows;
      /**
       * The react-table selection state also includes selected parent nodes with children which are not selected.
       * This is not the desired behaviour, so we remove the parent node from the selection state.
       */
      allRows.forEach((row) => {
        const parentRow = row.getParentRow();
        if (row.subRows) {
          const selectedChildren = row.subRows.filter((subRow) => prepareState[subRow.id] === true);
          if (selectedChildren.length !== row.subRows.length) {
            delete prepareState[row.id];
            if (parentRow) {
              delete prepareState[parentRow.id];
            }
          }
        }

        // if all children are selected of the parent, set the parent to true
        if (parentRow && parentRow.subRows) {
          const selectedChildren = parentRow.subRows.filter((subRow) => prepareState[subRow.id] === true);
          if (selectedChildren.length === parentRow.subRows.length) {
            prepareState[parentRow.id] = true;
          }
        }
      });
      const enhancedState = { ...setState(prepareState) };
      onSelected?.(enhancedState);
    };
  };

  return {
    handleSelection,
  };
}
