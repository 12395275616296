import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { API_ENDPOINT } from "../../constants";
import { ApiBaseResponse } from "../../types/ApiBaseResponse";
import { ReportType } from "../../types/Report";
import { ReportFilterQueryParams, ReportFilterResponse } from "../../types/ReportFilter";
import { requestJson } from "../api";
import { reportKeys } from "./reportKeys";

export const useGetReportFilters = (params: ReportFilterQueryParams, options?: { enabled: boolean }) => {
  const router = useRouter();
  const { reportType } = router.query;
  const query = useQuery(
    reportKeys.filters(reportType as ReportType, params),
    () =>
      requestJson<ApiBaseResponse<ReportFilterResponse>>(
        `${API_ENDPOINT}/app/reports/${reportType as ReportType}/filters`,
        {
          queryParams: params,
        }
      ).then(({ data }) => data),

    {
      ...options,
      enabled: options?.enabled ?? Boolean(reportType),
      keepPreviousData: true,
    }
  );

  return query;
};
