import React from "react";
import { InputProps } from "./Input.types";
import styles from "./Input.module.scss";
import classNames from "classnames";

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <input ref={ref} className={classNames(styles.Input, props.className)} data-testid="Input" {...props}></input>;
});

Input.displayName = "Input";
