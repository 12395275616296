import * as d3 from "d3";
import { FC } from "react";
import { ReportDataPoint, ReportEntity } from "../../../types/Report";
import styles from "./ReportLine.module.scss";

type Props = {
  entity?: ReportEntity;
  yScaleFunction?: d3.ScaleLinear<number, number>;
  xScaleFunction?: d3.ScaleTime<number, number>;
  id: string;
  stroke: string;
  strokeDasharray?: string;
};

const LINE_WIDTH = 3;

export const ReportLine: FC<Props> = ({ entity, id, yScaleFunction, xScaleFunction, stroke, strokeDasharray }) => {
  if (!yScaleFunction || !xScaleFunction || !entity) {
    return null;
  }

  const lineFunction = d3
    .line<ReportDataPoint>()
    .x((d) => xScaleFunction(new Date(d.date)))
    .y((d) => yScaleFunction(d.progress_percentage))
    .curve(d3.curveMonotoneX); // apply smoothing to the line

  const parsedData = {
    entity: entity,
    d: lineFunction(entity.datapoints) || "",
    stroke: entity.education_level?.label ? `var(--bg-${entity.education_level.label.toLowerCase()}-base)` : "black",
  };

  return (
    <g>
      <path
        key={id}
        className={styles.line}
        d={parsedData.d}
        fill="none"
        stroke={stroke}
        strokeDasharray={strokeDasharray ?? ""}
        strokeWidth={LINE_WIDTH}
      />
      {entity.datapoints.map((point, index) => (
        <circle
          key={index}
          cx={xScaleFunction(new Date(point.date))}
          cy={yScaleFunction(point.progress_percentage)}
          r={LINE_WIDTH - 0.5}
          fill={stroke}
        />
      ))}
    </g>
  );
};
