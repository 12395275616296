import React, { PropsWithChildren, useRef } from "react";
import { TreeMetaNode, TreeSelectedNode } from "./useTreeSelect";

type TreeSelectContextProps = {
  tags: TreeSelectedNode<TreeMetaNode>[];
  setTags: React.Dispatch<React.SetStateAction<TreeSelectedNode<TreeMetaNode>[]>>;
  onDeleteTag: (item: TreeSelectedNode) => () => void;
  setOnDeleteTag: (next: (item: TreeSelectedNode) => () => void) => void;
};

export const TreeSelectContext = React.createContext<TreeSelectContextProps>({
  tags: [],
  setTags: () => {},
  onDeleteTag: (item: TreeSelectedNode) => () => {},
  setOnDeleteTag: () => {},
});

export const TreeSelectProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tags, setTags] = React.useState<TreeSelectedNode<TreeMetaNode>[]>([]);
  const onDeleteTag = useRef((item: TreeSelectedNode) => () => {});

  const setOnDeleteTag = (next: (item: TreeSelectedNode) => () => void) => {
    onDeleteTag.current = next;
  };

  return (
    <TreeSelectContext.Provider value={{ tags, setTags, onDeleteTag: onDeleteTag.current, setOnDeleteTag }}>
      {children}
    </TreeSelectContext.Provider>
  );
};
