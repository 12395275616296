import * as d3 from "d3";
import { throttle } from "lodash";
import { useCallback } from "react";
import { ReportEntity } from "../../../types/Report";
import { GraphClosestPoint } from "../../../types/ReportGraph";
import { useTooltip } from "./ReportTooltip.context";
import { useConstants } from "./useConstants";

type Options = {
  lines: ReportEntity[];
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  rootRef: React.RefObject<SVGSVGElement>;
};

export function usePointer({ lines, xScale, yScale, rootRef }: Options) {
  const { GRAPH_WIDTH, GRAPH_HEIGHT } = useConstants();
  const { setTooltipData, tooltipData, hoveringTooltip } = useTooltip();

  // in all lines, and within the datapoints for each line
  // find the datapoint that is closest to the current mouse position, can be multiple
  const findClosestPoints = (mouseX: number, mouseY: number): GraphClosestPoint[] => {
    const closestPointPerLine: GraphClosestPoint[] = [];

    lines.forEach((line) => {
      const closestPoint = line.datapoints.reduce(
        (min: GraphClosestPoint, datapoint: { date: string; progress_percentage: number }) => {
          const xPixelPoint: number = xScale(new Date(datapoint.date));
          const yPixelPoint: number = yScale(datapoint.progress_percentage);
          const linearDistance: number = Math.floor(Math.hypot(mouseX - xPixelPoint, mouseY - yPixelPoint));

          return linearDistance < min.distance ? { distance: linearDistance, data: line, datapoint } : min;
        },
        { distance: Infinity, data: null, datapoint: null }
      );

      closestPointPerLine.push(closestPoint);
    });

    // return closestPointPerLine with the lowest distance. Return multiple if they are the same
    const closestPoints = closestPointPerLine.reduce(
      (minPoints: { distance: number; points: GraphClosestPoint[] }, point: GraphClosestPoint) => {
        if (point.distance < minPoints.distance) {
          return { distance: point.distance, points: [point] };
        } else if (point.distance === minPoints.distance) {
          return { ...minPoints, points: [...minPoints.points, point] };
        } else {
          return minPoints;
        }
      },
      { distance: Infinity, points: [] }
    );

    // Note: two datapoints can be different percentage, but have same distance
    const equalProgressPoints = closestPoints.points
      .filter((point) => {
        return point?.datapoint?.progress_percentage === closestPoints.points[0].datapoint?.progress_percentage;
      })
      .reverse();

    return equalProgressPoints;
  };

  const handlePointerMove: React.PointerEventHandler<SVGSVGElement> = useCallback(
    throttle(
      (e: React.PointerEvent<SVGSVGElement>) => {
        if (!xScale || !yScale || hoveringTooltip) return;

        const [mouseX, mouseY] = d3.pointer(e);
        const closestPoints = findClosestPoints(mouseX, mouseY);

        if (!closestPoints || closestPoints.length === 0) return;

        // use the first found for tooltip positioning
        const closestPoint = closestPoints[0];

        // ignore if not close to a point
        if (closestPoint.distance > 60) {
          handlePointerLeave();
          return;
        }

        const containerWidth = rootRef.current?.getBoundingClientRect().width || 0;
        const containerHeight = rootRef.current?.getBoundingClientRect().height || 0;

        // correct for when SVG size is not the determined size
        const closestPointX = xScale(new Date(closestPoint.datapoint?.date || 0)) * (containerWidth / GRAPH_WIDTH);
        const closestPointY =
          yScale(closestPoint.datapoint?.progress_percentage || 0) * (containerHeight / GRAPH_HEIGHT);

        // should not render if no data is found
        if (!closestPoint.data || !closestPoint.datapoint) return;

        // position the tooltip
        const position = {
          left: "auto",
          right: "auto",
          bottom: "auto",
          top: "auto",
          opacity: "1",
        };

        if (closestPointX > containerWidth / 2) {
          position.right = `${containerWidth - closestPointX}px`;
        } else {
          position.left = `${closestPointX}px`;
        }

        const vSpacing = 8;
        if (closestPointY > containerHeight / 2) {
          position.bottom = `${containerHeight - closestPointY + vSpacing}px`;
        } else {
          position.top = `${closestPointY + vSpacing}px`;
        }

        const tooltips = closestPoints.map((data) => {
          return {
            entity: data.data,
            datapoint: data.datapoint,
            color: data?.data?.education_level?.label ? data.data.education_level.label.toLowerCase() : "fallback",
          };
        });

        // set the tooltip data and position
        setTooltipData({ tooltips, position });
      },
      50,
      // trailing gave issues.. due to spawing the tooltip container the X and Y
      // coordinates of the e event were way off on the seconds call
      { leading: true, trailing: false }
    ),
    [lines, hoveringTooltip, xScale, yScale, tooltipData?.position?.opacity]
  );

  const handlePointerLeave = () => {
    if (!tooltipData || tooltipData?.position?.opacity === "0") return;

    setTooltipData({ ...tooltipData, position: { ...tooltipData.position, opacity: "0" } });
  };

  return {
    handlePointerMove,
    handlePointerLeave,
  };
}
