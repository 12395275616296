import type { ReportLabelType } from "../../../types/Report";
import { Chip } from "../../atoms/Chip";
import styles from "./ReportGraph.module.scss";

type Props = ReportLabelType & {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const ReportLabel: React.FC<Props> = ({ id, color, title, onClick }) => {
  return (
    <div key={title} role="button" className={styles.graphLabel} onClick={onClick} aria-disabled={id === undefined}>
      <Chip color={color}></Chip>
      <span>{title}</span>
    </div>
  );
};
