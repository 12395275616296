import { useIsFetching } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { SelectControl } from "../../../SelectControl/SelectControl";
import { SelectControlOption } from "../../../SelectControl/SelectControl.types";
import { ReportFieldProps } from "../../ReportField";
import { useRouterReportFilters } from "../../ReportFilters.context";

export const ReportFieldSelect: React.FC<ReportFieldProps> = ({
  field_id,
  field_label,
  field_required,
  field_disabled,
  field_control,
  field_options,
}) => {
  const { t } = useTranslation();
  const { addFilter } = useRouterReportFilters();
  const isFetching =
    useIsFetching({
      queryKey: ["report", "filters"],
    }) > 0;
  const handleChange = (value: SelectControlOption | null) => {
    if (!value) {
      return;
    }

    addFilter(field_id, value.value, {
      replaceExisting: field_control === "select",
    });
  };

  return (
    <SelectControl
      label={`${field_label}${field_required ? " *" : ""}`}
      placeholder={t("select")}
      disabled={field_disabled || isFetching}
      options={field_options?.map((o) => {
        return {
          label: o.label,
          value: o.value as string,
        };
      })}
      value={field_options?.find((o) => o.selected) as SelectControlOption}
      name={field_id}
      onChange={handleChange}
    />
  );
};
