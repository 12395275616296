import { GetStaticProps, NextPage } from "next";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { AuthorizedGuard } from "../../components/atoms/AuthorizedGuard/AuthorizedGuard";
import { PageLoader } from "../../components/atoms/PageLoader/PageLoader";
import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout/AuthenticatedLayout";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import { ReportFilters } from "../../components/molecules/ReportFilters/ReportFilters";
import { ReportFiltersProvider } from "../../components/molecules/ReportFilters/ReportFilters.context";
import { ReportGraph } from "../../components/organisms/ReportGraph/ReportGraph";
import { ReportTooltipProvider } from "../../components/organisms/ReportGraph/ReportTooltip.context";
import { createPageTitle } from "../../helpers/createPageTitle";
import { AuthorizationPermissionKey } from "../../types/Authorization";
import { ReportRouteKey, ReportType } from "../../types/Report";

export const validReportTypes: {
  route: ReportRouteKey;
  key: ReportType;
  translationKey: string;
  permission: AuthorizationPermissionKey;
}[] = [
  {
    route: "docenten",
    key: "teachers",
    translationKey: "teachers",
    permission: "report_teacher_class_show",
  },
  {
    route: "leerlingen",
    key: "student",
    translationKey: "students",
    permission: "report_student_show",
  },
  {
    route: "lesgroep",
    key: "subject",
    translationKey: "subject_groups",
    permission: "report_class_student_show",
  },
  {
    route: "klassen",
    key: "classes",
    translationKey: "classes",
    permission: "report_class_show",
  },
];

type PageProps = {
  reportType: ReportRouteKey;
};

const _Page: NextPage<PageProps> = ({ reportType }) => {
  const { t } = useTranslation();
  const currentPage = validReportTypes.find((report) => report.route === reportType);

  return (
    // if no currentPage is handled by getStaticProps, which shows a 404
    <AuthorizedGuard has={currentPage?.permission || []}>
      <AuthenticatedLayout>
        <Head>
          <title>{createPageTitle(t, t("progress"), t(currentPage?.translationKey ?? ""))}</title>
        </Head>
        <PageLoader>
          <PageHeader title={t("progress_report", { reportType: t(currentPage?.translationKey ?? "") })} />
          {/* Note: key is used to re-render the component by force due to the query cache keeps being persistent */}
          <ReportFilters key={JSON.stringify(reportType)} />
          <ReportTooltipProvider>
            <ReportGraph />
          </ReportTooltipProvider>
        </PageLoader>
      </AuthenticatedLayout>
    </AuthorizedGuard>
  );
};

export const getStaticProps: GetStaticProps<PageProps> = (props) => {
  const reportType = props.params?.reportType as ReportRouteKey;
  const hasReportType = validReportTypes.some((validReportType) => validReportType.route === reportType);

  if (!reportType || !hasReportType) {
    return {
      notFound: true,
      props: {},
    };
  }

  return {
    props: {
      reportType: reportType,
    },
  };
};

export const getStaticPaths = async () => {
  return {
    paths: validReportTypes.map((reportType) => ({
      params: { reportType: reportType.route },
    })),
    fallback: false,
  };
};

const Page: NextPage<PageProps> = (props) => {
  return (
    <ReportFiltersProvider>
      <_Page {...props} />
    </ReportFiltersProvider>
  );
};

export default Page;
