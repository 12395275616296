import { FC } from "react";
import { UserProps } from "./User.types";
import styles from "./User.module.css";
import { Avatar } from "../../atoms/Avatar";
import { getInitialsFromName } from "../../../helpers/format/user";
import { AVATAR_SIZES } from "../../atoms/Avatar/Avatar.constants";

export const User: FC<UserProps> = ({ image, email, title, text, color, dataMinimized }) => (
  <div className={styles.user} data-testid="user">
    <Avatar
      color={color}
      image={image}
      initials={getInitialsFromName(title ?? "")}
      size={dataMinimized ? AVATAR_SIZES.SM : AVATAR_SIZES.MD}
      data-minimized={dataMinimized}
    />
    <div className={styles.userContent} data-minimized={dataMinimized}>
      <span className={styles.userTitle} title={title}>
        {title}
      </span>
      <span className={styles.userText} title={text}>
        {text}
      </span>
    </div>
  </div>
);
