import { useIsFetching } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useClickAway } from "react-use";
import { ReportFilterId } from "../../../../../types/ReportFilter";
import { useRouterReportFilters } from "../../ReportFilters.context";

interface ReportFieldDateRangeOptions {
  ref?: React.RefObject<HTMLDivElement>;
  id: ReportFilterId;
  label: string;
  dates?: string[];
  disabled?: boolean;
}

export function useReportFieldDateRange({ ref, id, label, dates, disabled }: ReportFieldDateRangeOptions) {
  const { addFilter, filters } = useRouterReportFilters();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const initialDates = filters?.[`filter[${id}]`] ?? dates;
  const [dateFrom, setDateFrom] = useState(initialDates?.[0] ?? "");
  const [dateTo, setDateTo] = useState(initialDates?.[1] ?? "");
  const isFetching =
    useIsFetching({
      queryKey: ["report", "filters"],
    }) > 0;

  const handleChange = (name: string, value: string) => {
    if (name === "date-from") {
      setDateFrom(value);
    }
    if (name === "date-to") {
      setDateTo(value);
    }
  };

  const handleBlur = () => {
    addFilter(id, [dateFrom, dateTo], {
      replaceExisting: true,
    });
  };

  const setDates = (dateFrom: string, dateTo: string) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const handleEscape = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Update dates if they change from outside
  useEffect(() => {
    if (dateTo === undefined && dateFrom === undefined) {
      setDateFrom(initialDates?.[0] ?? "");
      setDateTo(initialDates?.[1] ?? "");
    }
    // if presets change to be selected from the backend, set them
    if (!dateTo && !dateFrom && initialDates && initialDates.length === 2) {
      setDateFrom(initialDates[0]);
      setDateTo(initialDates[1]);
    }
  }, [initialDates]);

  // Reset dates if disabled
  useEffect(() => {
    if (disabled) {
      setOpen(false);
      setDateFrom("");
      setDateTo("");
    }
  }, [disabled]);

  // Set preset dates from the query params if not implicitly set
  useEffect(() => {
    if (dates && !dates.includes("") && !dateFrom && !dateTo) {
      addFilter(id, [dates[0], dates[1]], {
        replaceExisting: true,
      });
    }
  }, [dates, dateFrom, dateTo]);

  useClickAway(ref!, () => {
    setOpen(false);
  });

  return {
    open,
    setOpen,
    disabled: isFetching || disabled,
    dateTo,
    dateFrom,
    setDates,
    placeholder: dateFrom && dateTo ? `${dateFrom} - ${dateTo}` : t("select"),
    handleChange,
    handleEscape,
    handleBlur,
  };
}
