import * as RCheckbox from "@radix-ui/react-checkbox";
import React, { useEffect, useRef, useState } from "react";
import { Checkmark, Dash } from "../icons";
import styles from "./Checkbox.module.scss";

interface CheckboxProps extends RCheckbox.CheckboxProps {
  isIndeterminate?: boolean;
  children?: React.ReactNode;
}
export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  id,
  isIndeterminate,
  checked,
  onCheckedChange,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isChecked, setIsChecked] = useState<CheckboxProps["checked"]>(
    isIndeterminate ? "indeterminate" : Boolean(checked)
  );

  useEffect(() => {
    setIsChecked(isIndeterminate ? "indeterminate" : Boolean(checked));
  }, [isIndeterminate, checked]);

  const handleLabelClick = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    ref.current?.click();
  };

  const handleCheckedChange: CheckboxProps["onCheckedChange"] = (value) => {
    setIsChecked(value);
    onCheckedChange?.(value);
  };

  return (
    <div
      data-testid="checkbox"
      className={styles.Wrapper}
      onClick={(e) => {
        // Prevent the row from being expanded/collapsed
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <RCheckbox.Root
        ref={ref}
        className={styles.Root}
        id={id}
        checked={isChecked}
        role="checkbox"
        onCheckedChange={handleCheckedChange}
        {...props}
      >
        <RCheckbox.Indicator className={styles.Indicator}>
          {isIndeterminate && <Dash />}
          {checked && !isIndeterminate && <Checkmark />}
        </RCheckbox.Indicator>
      </RCheckbox.Root>
      {React.Children.count(children) > 0 && (
        <label className={styles.Label} id={`label-${id}`} htmlFor={id} onClick={handleLabelClick}>
          {children}
        </label>
      )}
    </div>
  );
};
