import { TreeDataItem } from "../../atoms/Tree/Tree";
import { TreeMetaNode, TreeSelectedNode, TreeSelection } from "./useTreeSelect";

export function useTreeSelectHelpers() {
  const convertRowSelection = (rowSelection: TreeSelection, data: TreeDataItem<TreeMetaNode>[]) => {
    let selected = Object.entries(rowSelection)
      .map(([key, selection]) => {
        if (selection) {
          const item = findNested(rowSelection, key, data);
          if (!item) return null;

          return {
            meta: {
              ...item.meta,
              rowIndex: key,
              id: item.id,
              children: item.children,
            },
            checked: selection,
            label: item.name,
          };
        }
      })
      .filter(Boolean) as TreeSelectedNode[];
    // remove duplicates on key
    selected = selected.reduce((acc, item) => {
      const existing = acc.find((i) => i.meta.id === item.meta.id);
      if (!existing) {
        return [...acc, item];
      }
      return acc;
    }, [] as TreeSelectedNode[]);

    return selected;
  };

  const deleteRowSelection = (rowSelection: Record<string, boolean>, key: string) => {
    // set all siblings that start with the key to false
    const rowSelectionKeys = Object.keys(rowSelection);
    const siblings = rowSelectionKeys.filter((item) => item.startsWith(key));
    const newSelection = siblings.reduce((acc, item) => {
      return {
        ...acc,
        [item]: false,
      };
    }, rowSelection);

    return newSelection;
  };

  const areAllChildrenSelected = (
    rowSelection: Record<string, boolean>,
    item: TreeDataItem<TreeMetaNode>,
    parentKey: string,
    id: string
  ): boolean => {
    let result = false;
    if (item.children) {
      result = item.children.every((_, i) => {
        const childKey = `${parentKey}.${i}`;

        if (!item?.children?.[i]) return false;
        const child = item.children?.[i];
        return (
          rowSelection[childKey] === true ||
          childKey === id ||
          (child && areAllChildrenSelected(rowSelection, child, childKey, id))
        );
      });
    }
    return result;
  };

  const findNested = (
    rowSelection: Record<string, boolean>,
    id: string, // the id of the node to find
    data: TreeDataItem<TreeMetaNode>[],
    parent?: string,
    originalId?: string
  ): TreeDataItem<TreeMetaNode> | null => {
    const [first, ...rest] = id.split(".");
    const item = data[parseInt(first, 10)];
    const parentKey = parent ? `${parent}.${first}` : first;
    const clickedId = originalId ?? id;

    if (!item) return null;

    const childsAllSelected = areAllChildrenSelected(rowSelection, item, parentKey, clickedId);

    if (!rowSelection[parentKey] && childsAllSelected) {
      return item;
    }

    if (rowSelection[parentKey] && rest.length === 1 && childsAllSelected) {
      return null;
    }

    if (rest.length === 0) {
      return item;
    }

    return findNested(rowSelection, rest.join("."), item.children || [], first, id);
  };

  return {
    convertRowSelection,
    deleteRowSelection,
    areAllChildrenSelected,
    findNested,
  };
}
