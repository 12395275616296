import { useQuery } from "@tanstack/react-query";

import { API_ENDPOINT } from "../../constants";
import { ApiBaseResponse } from "../../types/ApiBaseResponse";
import { DashboardClassItem } from "../../types/Dashboard";
import { requestJson } from "../api";
import { classKeys } from "./classKeys";

export const useGetClasses = (options?: { enabled: boolean }) => {
  return useQuery(
    classKeys.all,
    () => requestJson<ApiBaseResponse<DashboardClassItem[]>>(`${API_ENDPOINT}/app/classes`).then(({ data }) => data),
    options
  );
};
