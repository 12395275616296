import { FC } from "react";
import { ChipProps } from "./Chip.types";
import styles from "./Chip.module.scss";

export const Chip: FC<ChipProps> = ({ children, color = "#000" }) => (
  <div className={styles.chip} data-testid="chip">
    <span style={{ backgroundColor: color }} />
    {children}
  </div>
);
