import { ReportFilter } from "../../../types/ReportFilter";
import { ReportFieldDateRange } from "./fields/ReportFieldDateRange/ReportFieldDateRange";
import { ReportFieldMultiSelect } from "./fields/ReportFieldMultiSelect/ReportFieldMultiSelect";
import { ReportFieldSelect } from "./fields/ReportFieldSelect/ReportFieldSelect";

export type ReportFieldProps = ReportFilter;

const ReportFieldFactory: Record<ReportFilter["field_control"], React.FC<ReportFieldProps>> = {
  date: ReportFieldDateRange,
  select: ReportFieldSelect,
  multiselect: ReportFieldMultiSelect,
};

export const ReportField: React.FC<ReportFieldProps> = (props) => {
  if (!ReportFieldFactory[props.field_control]) {
    console.error(`ReportFieldFactory does not have a component for ${props.field_control}`);
    return null;
  }
  const Component = ReportFieldFactory[props.field_control];

  if (!Component) {
    return null;
  }

  return <Component {...props} />;
};
