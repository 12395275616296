import * as RDialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Button, ButtonVariant } from "../../atoms/Button";
import { ButtonSize } from "../../atoms/Button/Button.types";
import Icon from "../../atoms/icons";
import styles from "./Dialog.module.scss";

export interface DialogProps extends PropsWithChildren, RDialog.DialogProps {
  title: string;
  description?: string;
  trigger: React.ReactNode;
  maxWidth?: number;
  actions?: {
    label: string;
    disabled?: boolean;
    loading?: boolean;
    autoClose?: boolean;
    onClick?: () => void;
  }[];
}
export const Dialog: React.FC<DialogProps> = ({
  children,
  title,
  description,
  trigger,
  maxWidth,
  actions = [],
  ...props
}) => {
  return (
    <RDialog.Root {...props}>
      <RDialog.Trigger asChild>{trigger}</RDialog.Trigger>
      <RDialog.Portal>
        <RDialog.Overlay className={styles.Overlay} />
        <RDialog.Content
          className={styles.Content}
          style={{ maxWidth }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.Header}>
            <RDialog.Title className={classNames(styles.Title, { [styles.TitleMargin]: !description })}>
              {title}
            </RDialog.Title>
            {description && <RDialog.Description className={styles.Description}>{description}</RDialog.Description>}
            <RDialog.Close asChild>
              <Button
                className={styles.CloseButton}
                variant={ButtonVariant.PLAIN}
                size={ButtonSize.SMALL}
                aria-label="Close"
                icon={<Icon name="close" />}
              ></Button>
            </RDialog.Close>
          </div>
          <div>{children}</div>
          <div className={styles.Actions}>
            {actions.map(({ autoClose = true, ...action }, index) => {
              const button = (
                <Button
                  className={styles.Action}
                  onClick={action.onClick}
                  disabled={action.disabled}
                  size={ButtonSize.MEDIUM}
                  loading={action.loading}
                  key={index}
                >
                  {action.label}
                </Button>
              );

              if (autoClose) {
                return (
                  <RDialog.Close key={action.label} asChild>
                    {button}
                  </RDialog.Close>
                );
              }

              return button;
            })}
          </div>
        </RDialog.Content>
      </RDialog.Portal>
    </RDialog.Root>
  );
};
