import React, { FC, PropsWithChildren, createContext, useContext, useState } from "react";
import { Dialog } from "../../organisms/Dialog";
import styles from "./KeyboardShortcutsDialog.module.scss";
import { KeyMapItem, KeyboardShortcutsDialogProps } from "./KeyboardShortcutsDialog.types";
import { useEvent } from "react-use";
import { isAppleDevice } from "../../../helpers/os";
import { useTranslation } from "react-i18next";

// List of Keyboard Symbols:
// https://gist.github.com/jlyonsmith/6992156f18c423fd1c5af068aa311fb5

const keyMap: KeyMapItem[] = [
  {
    title: "Beoordeelscherm",
    column: 1,
    lines: [
      { label: "next-student", keys: ["→"] },
      { label: "prev-student", keys: ["←"] },
      { label: "next-goal", keys: ["↓"] },
      { label: "prev-goal", keys: ["↑"] },
      { label: "first-goal", keys: ["HOME"] },
      { label: "last-goal", keys: ["END"] },
      { label: "assess-skill-1", keys: ["1"] },
      { label: "assess-skill-2", keys: ["2"] },
      { label: "assess-skill-3", keys: ["3"] },
      { label: "assess-skill-4", keys: ["4"] },
    ],
  },
  {
    title: "Overig",
    column: 2,
    lines: [
      { label: "shortcuts", keys: { universal: ["CTRL", "?"], apple: ["⌘", "?"] } },
      { label: "search", keys: { universal: ["CTRL", "F"], apple: ["⌘", "F"] } },
      { label: "close-dialog", keys: ["ESC"] },
    ],
  },
];

const KeyboardShortcutsColumn: FC<{ keyMap: KeyMapItem[] }> = ({ keyMap }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.KeyboardShortcutsDialogColumn}>
      {keyMap.map((category, i) => (
        <div key={i} className={styles.KeyboardShortcutsDialogKeyMapItem}>
          <div className={styles.KeyboardShortcutsDialogTitle}>{category.title}</div>
          {category.lines.map((line, i2) => (
            <div key={i2} className={styles.KeyboardShortcutsDialogLine}>
              <span>{t(`shortcuts.${line.label}`)}</span>
              <div className={styles.KeyboardShortcutsDialogKeys}>
                {(Array.isArray(line.keys) ? line.keys : (isAppleDevice && line.keys.apple) || line.keys.universal).map(
                  (key, i3) => (
                    <div key={i3} className={styles.KeyboardShortcutsDialogKey}>
                      {key}
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export type KeyboardShortcutsContextType = {
  showDialog: () => void;
};

export const KeyboardShortcutsContext = createContext<KeyboardShortcutsContextType>({
  showDialog: () => {},
});

export const useKeyboardShortcutsContext = () => useContext(KeyboardShortcutsContext);

export const KeyboardShortcutsDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);

  useEvent("keydown", (event: KeyboardEvent) => {
    const modifierKey = isAppleDevice ? event.metaKey : event.ctrlKey;

    if (modifierKey && event.code === "Slash") {
      setOpen(!open);
    }
  });

  const showDialog = () => {
    setOpen(true);
  };

  return (
    <KeyboardShortcutsContext.Provider value={{ showDialog }}>
      {children}

      <KeyboardShortcutsDialog open={open} onOpenChange={setOpen} />
    </KeyboardShortcutsContext.Provider>
  );
};

export const KeyboardShortcutsDialog: FC<KeyboardShortcutsDialogProps> = (props) => (
  <Dialog
    title="Sneltoetsen"
    trigger={null}
    actions={[{ label: "Sluiten", autoClose: true }]}
    maxWidth={769}
    {...props}
  >
    <div className={styles.KeyboardShortcutsDialog}>
      <KeyboardShortcutsColumn keyMap={keyMap.filter((item) => item.column === 1)} />
      <KeyboardShortcutsColumn keyMap={keyMap.filter((item) => item.column === 2)} />
    </div>
  </Dialog>
);
