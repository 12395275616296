import { useGetReportFilters } from "../../../services/report/useGetReportFilters";
import { ReportField } from "./ReportField";
import { useRouterReportFilters } from "./ReportFilters.context";
import { ReportFieldSkeleton } from "./fields/ReportFieldSkeleton/ReportFieldSkeleton";

type Props = {};

export const ReportFilters: React.FC<Props> = () => {
  const { filters } = useRouterReportFilters();
  const { data, isLoading, isInitialLoading } = useGetReportFilters({
    ...filters,
  });

  return (
    <div
      data-testid="report-filters"
      style={{
        display: "flex",
        gap: 16,
        paddingBottom: 16,
        flexWrap: "wrap",
      }}
    >
      {isInitialLoading && Array.from({ length: 5 }).map((_, index) => <ReportFieldSkeleton key={index} />)}
      {!isLoading &&
        data?.map((filter) => {
          return <ReportField key={filter.field_id} {...filter} />;
        })}
    </div>
  );
};
