import * as Collapsible from "@radix-ui/react-collapsible";
import classNames from "classnames";
import React, { useId } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "../../../../atoms/Button";
import { ButtonSize } from "../../../../atoms/Button/Button.types";
import { Input } from "../../../../atoms/Input";
import ChevronDown from "../../../../atoms/icons/ChevronDown";
import ChevronUp from "../../../../atoms/icons/ChevronUp";
import { FormControl } from "../../../FormControl";
import { ReportFieldProps } from "../../ReportField";
import { useRouterReportFilters } from "../../ReportFilters.context";
import styles from "./ReportFieldDateRange.module.scss";
import { useReportFieldDateRange } from "./useReportFieldDateRange";

export const ReportFieldDateRange: React.FC<ReportFieldProps> = ({
  field_label,
  field_disabled,
  field_id,
  field_required,
  field_options,
}) => {
  const classes = {
    root: styles.dateRange__root,
    trigger: styles.dateRange__trigger,
    content: styles.dateRange__content,
  };
  const { addFilter } = useRouterReportFilters();
  const ref = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const presetDate = field_options?.find((o) => o.selected)?.value;
  const { open, setOpen, handleEscape, disabled, dateTo, dateFrom, handleChange, handleBlur, setDates, placeholder } =
    useReportFieldDateRange({
      ref,
      id: field_id,
      label: field_label,
      dates: presetDate ? (presetDate as string).split(",") : ["", ""],
      disabled: field_disabled,
    });
  const id = useId();

  return (
    <Collapsible.Root
      ref={ref}
      className={classNames(styles.dateRange, classes.root)}
      open={open}
      onOpenChange={setOpen}
      onKeyDown={handleEscape}
    >
      <label className={styles.dateRange__label}>
        {field_label}
        {field_required ? " *" : ""}
      </label>
      <div className={styles.dateRange__wrapper}>
        <Collapsible.Trigger asChild>
          <div
            className={classNames(styles.dateRange__trigger, classes.trigger)}
            aria-disabled={field_disabled || disabled}
          >
            <Input
              id={field_id}
              name={field_id}
              className={styles.dateRange__input}
              placeholder={placeholder}
              readOnly
              disabled={field_disabled || disabled}
            />
            <div className={styles.dateRange__seperator}></div>
            <Button
              className={styles.dateRange__triggerButton}
              variant={ButtonVariant.PLAIN}
              size={ButtonSize.MEDIUM}
              disabled={field_disabled || disabled}
              icon={open ? <ChevronUp /> : <ChevronDown />}
            />
          </div>
        </Collapsible.Trigger>
      </div>
      <Collapsible.Content
        className={classNames(styles.dateRange__content, classes.content)}
        forceMount
        style={{
          display: open ? "flex" : "none",
        }}
      >
        <div className={styles.dateRange__contentWrapper}>
          <div className={styles.dateRange__contentItem}>
            <label className={styles.dateRange__contentLabel} htmlFor={`date_from_${id}`}>
              {t("from")} {field_required ? " *" : ""}
            </label>
            <FormControl
              control="input"
              id={`date_from_${id}`}
              name="date-from"
              type="date"
              value={dateFrom}
              onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
          <div className={styles.dateRange__contentItem}>
            <label className={styles.dateRange__contentLabel} htmlFor={`date_to_${id}`}>
              {t("to_date")} {field_required ? " *" : ""}
            </label>
            <FormControl
              control="input"
              id={`date_to_${id}`}
              name="date-to"
              type="date"
              value={dateTo}
              onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
        </div>
        {field_options && field_options.length > 0 && (
          <div className={styles.dateRange__contentWrapper}>
            <span className={styles.dateRange__contentLabel}>{t("or_select_period")}</span>
            <div className={styles.dateRange__contentItem}>
              {field_options.map((option) => {
                const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  const dates = (option.value as string).split(",");
                  setDates(dates[0], dates[1]);
                  addFilter(field_id, dates, {
                    replaceExisting: true,
                  });
                };
                return (
                  <Button
                    key={option.label}
                    variant={option.selected ? ButtonVariant.PRIMARY : ButtonVariant.PRIMARY_OUTLINE}
                    size={ButtonSize.SMALL}
                    onClick={handleClick}
                    disabled={disabled}
                    // avoid layout shift due to with and without border
                    style={{ borderWidth: 1, borderStyle: "solid" }}
                  >
                    {option.label}
                  </Button>
                );
              })}
            </div>
          </div>
        )}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
