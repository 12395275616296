import { Skeleton } from "../../../../atoms/Skeleton/Skeleton";

export const ReportFieldSkeleton: React.FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Skeleton style={{ width: 90, height: 18 }} />
      <Skeleton style={{ width: 180, height: 38 }} />
    </div>
  );
};
