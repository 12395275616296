import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TreeSelection } from "../../molecules/TreeSelect/useTreeSelect";
import styles from "./Tree.module.scss";
import { TreeNode } from "./TreeNode";
import { useRowSelection } from "./useRowSelection";
import { useTree } from "./useTree";

export interface TreeDataItem<TMeta = any> {
  id: string | number;
  name: string;
  meta: {
    id?: string | number;
    color?: string;
    type?: "GOAL" | "SUB_GOAL" | "EDUCATION_LEVEL";
  };
  children?: TreeDataItem<TMeta>[];
}
interface TreeProps<TMeta> {
  data: TreeDataItem<TMeta>[];
  filter?: string;
  disabled?: boolean;
  selected: TreeSelection;
  onSelected: (selected: TreeSelection) => void;
  onFilter?: (search: string) => void;
}

export function Tree<TMeta>({ data, onFilter, selected, filter, onSelected, disabled }: TreeProps<TMeta>) {
  const { expanded, setExpanded, globalFilterFn } = useTree({});
  const { t } = useTranslation();
  const table = useReactTable<TreeDataItem<TMeta>>({
    state: {
      expanded,
      globalFilter: filter,
      rowSelection: selected as Record<string, boolean>,
    },
    enableRowSelection: disabled ? false : true,
    onExpandedChange: setExpanded,
    columns: [
      {
        id: "name",
        accessorFn: (row) => row.name,
        cell: TreeNode,
      },
    ],
    data,
    onGlobalFilterChange: onFilter,
    enableGlobalFilter: true,
    getColumnCanGlobalFilter(column) {
      return column.id === "name";
    },
    globalFilterFn: globalFilterFn,
    getSubRows: (row) => row.children,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const { handleSelection } = useRowSelection({ selected, onSelected });

  table.setOptions((prev) => ({
    ...prev,
    onRowSelectionChange: handleSelection(table),
  }));

  return (
    <ol role="tree" className={styles.tree} data-testid="tree">
      {table.getRowModel().rows.map((row) => {
        const cell = row.getVisibleCells()[0];

        return <Fragment key={row.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>;
      })}
      {/* No results */}
      {table.getRowModel().rows.length === 0 && (
        <li className={classNames(styles.tree__node)}>
          <div className={styles.tree__noResults}>{t("no_matches")}</div>
        </li>
      )}
    </ol>
  );
}
