import React from "react";
import { useAuthorization, ValidatePermissionStrategy } from "../../../context/AuthorizationContext";
import { AuthorizationPermissionKey } from "../../../types/Authorization";

type Props = {
  as?: React.ReactNode;
  has: AuthorizationPermissionKey | AuthorizationPermissionKey[];
  strategy?: ValidatePermissionStrategy;
  children: React.ReactNode;
};

export const AuthorizedCan: React.FC<Props> = ({ has: permission, as, strategy, children }) => {
  const { hasPermission } = useAuthorization();

  if (!hasPermission(permission, { strategy })) {
    if (React.isValidElement(as)) {
      return as;
    }

    return null;
  }

  return <>{children}</>;
};
