import { FC } from "react";
import { User } from "../User";
import { AuthenticatedUserProps } from "./AuthenticatedUser.types";
import { useMeta } from "../../../context/MetaContext";
import { ChevronDown } from "../../atoms/icons";
import styles from "./AuthenticatedUser.module.scss";
export const AuthenticatedUser: FC<AuthenticatedUserProps> = ({ dataMinimized }) => {
  const { user: metaUser } = useMeta();

  return (
    <div className={styles.authenticatedUser} data-testid="authenticated-user">
      <User title={metaUser.display_name} email={metaUser.email} dataMinimized={dataMinimized} />
      <ChevronDown className={styles.chevronRight} data-minimized={dataMinimized} />
    </div>
  );
};
