import React, { useContext, useEffect } from "react";
import { useClickAway } from "react-use";
import { TreeSelectProps } from "./TreeSelect";
import { TreeSelectContext } from "./TreeSelect.context";
import { useTreeSelectHelpers } from "./useTreeSelectHelpers";

export type TreeMetaNode = {
  id: string | number;
  color: string;
  type: "EDUCATION_LEVEL" | "GOAL" | "SUB_GOAL";
  rowIndex?: string; // 0.4.2
  children?: TreeMetaNode[];
};

export type TreeSelection = Record<string, boolean>;

export interface TreeSelectedNode<TMeta = TreeMetaNode> {
  label: string;
  checked: boolean;
  meta: TMeta;
}

interface TreeSelectOptions {
  ref?: React.RefObject<HTMLDivElement>;
  data: TreeSelectProps["data"];
  onChange?: TreeSelectProps["onChange"];
  placeholder?: TreeSelectProps["placeholder"];
  initialOpen?: TreeSelectProps["initialOpen"];
  selected?: TreeSelectProps["selected"];
}

export function useTreeSelect(
  {
    ref,
    data,
    initialOpen = false,
    onChange,
    placeholder: _placeholder,
    selected: _selected = {},
  }: TreeSelectOptions = {
    data: [],
  }
) {
  const { convertRowSelection, deleteRowSelection } = useTreeSelectHelpers();
  const [open, setOpen] = React.useState(initialOpen);
  const [selected, setSelected] = React.useState<Record<string, boolean>>(_selected);
  const { tags, setTags, setOnDeleteTag } = useContext(TreeSelectContext);
  const [search, setSearch] = React.useState("");
  const numberOfSelected = tags.filter((i) => i.checked).length;
  const placeholder = numberOfSelected ? `${numberOfSelected} geselecteerd` : _placeholder;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!open) {
      setOpen(true);
    }
    setSearch(e.currentTarget.value);
  };

  const handleDelete = (item: TreeSelectedNode) => () => {
    let selected: Record<string, boolean> | undefined;
    setSelected((prev) => {
      selected = deleteRowSelection(prev, item.meta.rowIndex!);
      return selected;
    });
    setTags((prev) => prev.filter((i) => i.label !== item.label));
    onChange?.(convertRowSelection(selected!, data));
  };

  const handleEscape = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const handleSelected = (rowSelection: TreeSelection) => {
    const selected = convertRowSelection(rowSelection, data);

    setTags(selected);
    setSelected(rowSelection);
    onChange?.(selected);
  };

  useEffect(() => {
    if (_selected !== selected) {
      setTags(convertRowSelection(_selected, data));
      setSelected(_selected);
    }
  }, [_selected]);

  useEffect(() => {
    if (tags.length === 0) {
      setOnDeleteTag(handleDelete);
      setTags(convertRowSelection(_selected, data));
    }
  }, []);

  useClickAway(ref!, () => {
    setOpen(false);
  });

  return {
    open,
    setOpen,
    selected,
    setSelected,
    tags: tags,
    setTags: setTags,
    placeholder,
    search,
    setSearch,
    handleSearch,
    handleDelete,
    handleSelected,
    handleEscape,
  };
}
