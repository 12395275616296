import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "../../atoms/Button";
import { SignOutButtonProps } from "./SignOutButton.types";
import { ButtonSize } from "../../atoms/Button/Button.types";
import { useAuthentication } from "../../../auth/AuthenticationContext";
import { useMeta } from "../../../context/MetaContext";
import { useDepersonate } from "../../../services/users/useDepersonate";
import { showToast } from "../../../services/toast/toast";

export const SignOutButton: FC<SignOutButtonProps> = (props) => {
  const { t } = useTranslation();
  const { signOut } = useAuthentication();
  const { user } = useMeta();
  const { mutate: mutateDepersonate } = useDepersonate();

  const depersonate = () => {
    mutateDepersonate();

    showToast({ type: "success", message: t("depersonate", { name: user.display_name }) });
  };

  return (
    <Button
      data-testid="SignOutButton"
      onClick={user.is_impersonated ? depersonate : signOut}
      variant={ButtonVariant.SECONDARY_OUTLINE}
      size={ButtonSize.MEDIUM}
      {...props}
    >
      {user.is_impersonated ? t("back_to_my_account") : t("logout")}
    </Button>
  );
};
