import * as Collapsible from "@radix-ui/react-collapsible";
import React, { useId } from "react";

import classNames from "classnames";
import { Button, ButtonVariant } from "../../atoms/Button";
import { ButtonSize } from "../../atoms/Button/Button.types";
import { Input } from "../../atoms/Input";
import { Tree, TreeDataItem } from "../../atoms/Tree/Tree";
import { ChevronDown, ChevronUp } from "../../atoms/icons";
import styles from "./TreeSelect.module.scss";
import { TreeMetaNode, TreeSelectedNode, TreeSelection, useTreeSelect } from "./useTreeSelect";

export type TreeSelectProps = {
  data: TreeDataItem[];
  label: string;
  placeholder: string;
  initialOpen?: boolean;
  name?: string;
  disabled?: boolean;
  selected: TreeSelection;
  onChange?: (selected: TreeSelectedNode[]) => void;
  action?: (tags: TreeSelectedNode<TreeMetaNode>[]) => React.ReactNode;
  classes?: {
    root?: string;
    trigger?: string;
    content?: string;
    tagGroup?: string;
  };
};

export const TreeSelect: React.FC<TreeSelectProps> = ({
  classes = {},
  label,
  name,
  disabled,
  placeholder: _placeholder,
  data,
  selected: _selected,
  onChange,
  action,
  initialOpen = false,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const {
    tags,
    selected,
    placeholder,
    search,
    setSearch,
    open,
    setOpen,
    handleDelete,
    handleSearch,
    handleSelected,
    handleEscape,
  } = useTreeSelect({
    ref,
    data,
    onChange,
    placeholder: _placeholder,
    selected: _selected,
    initialOpen,
  });

  const treeId = useId();

  return (
    <Collapsible.Root
      ref={ref}
      className={classNames(styles.treeSelect, classes.root)}
      open={open}
      onOpenChange={setOpen}
      onKeyDown={handleEscape}
    >
      <label className={styles.treeSelect__label} htmlFor={treeId}>
        {label}
      </label>
      <div className={styles.treeSelect__wrapper}>
        <Collapsible.Trigger asChild>
          <div className={classNames(styles.treeSelect__trigger, classes.trigger)} aria-disabled={disabled}>
            <Input
              id={treeId}
              name={name ?? treeId}
              className={styles.treeSelect__input}
              disabled={disabled}
              placeholder={placeholder}
              value={search}
              onChange={handleSearch}
            />
            <Button
              className={styles.treeSelect__triggerButton}
              variant={ButtonVariant.PLAIN}
              size={ButtonSize.MEDIUM}
              disabled={disabled}
              data-testid="reflection__button"
              icon={open ? <ChevronUp /> : <ChevronDown />}
            />
          </div>
        </Collapsible.Trigger>
        {/* e.g. the "create assessment round" button */}
        {action && <div className={styles.treeSelect__actions}>{action(tags)}</div>}
      </div>
      <Collapsible.Content
        className={classNames(styles.treeSelect__content, classes.content)}
        forceMount
        style={{
          display: open ? "block" : "none",
        }}
      >
        <Tree
          data={data}
          onFilter={setSearch}
          filter={search}
          selected={selected}
          onSelected={handleSelected}
          disabled={disabled}
        />
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
